import { ArrowRight, Bell, Eye, FileText, Heart } from "react-feather"

import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"

const Feature = ({ icon, title, description, isFirst, isComingSoon }) => (
  <div
    className={`w-50-ns w-100 pv3-ns pr5-ns white mt0-ns ${
      isFirst ? "mt3" : "mt4"
    }`}
  >
    <div>{icon}</div>
    <div>
      <div>
        <h2 className="white mt2 mt3-ns">{title}</h2>
        {isComingSoon ? (
          <div className={"bg-white b coming-soon-tag br2 tc pv1 f7"}>
            COMING SOON
          </div>
        ) : null}
      </div>
      <p className="mt4 lh-copy">{description}</p>
    </div>
  </div>
)

const MainSection = () => (
  <div className="container flex flex-row-ns flex-column">
    <div className="w-60-ns w-100 tl-ns tc flex flex-column justify-center pa0-ns pa3">
      <h1 className="main-container-title f2 lh-title">
        Makeup organization made easy.
      </h1>
      <p className="lh-copy">
        Keep track of your makeup, skincare, hair products and more. Love your
        look of the day? Save to Looks so you'll never forget.
      </p>
      <div className="download-button-container">
        <a
          href="/signup"
          className={"download-button dn flex-ns justify-center items-center"}
        >
          Get Started
          <ArrowRight className={"ml2 download-signup-arrow"} />
        </a>
        <a
          href="https://apps.apple.com/tt/app/vanity-beauty-organization/id1476182117?ign-mpt=uo%3D2"
          className={
            "download-button flex justify-center items-center dn-ns ph4"
          }
        >
          Download the app
          <ArrowRight className={"ml2 download-signup-arrow"} />
        </a>
      </div>
    </div>
    <div className="phone-image-outer-container w-40-ns w-100 pa4-ns pb0-ns pr0-ns ph2">
      <div className="phone-image-container"></div>
    </div>
  </div>
)

const FeaturesSection = () => (
  <div className="container-pink">
    <div className="container flex flex-column pa3 pb5 ph0-ns">
      <h1 className="white mv5-ns mv4">Get the most out of your products</h1>
      <div className="features">
        <div className="flex flex-column">
          <div className="inner-container flex flex-row-ns flex-column">
            <Feature
              icon={<FileText />}
              title="Easily build a digital inventory"
              description={`Find your products in our database of 12,000+ items 
              and add to your inventory with one click (or add any item manually!)`}
              isFirst={true}
            />
            <Feature
              icon={<Eye />}
              title="Makeup looks you'll remember"
              description={`Take a photo right from the app or add existing ones 
              to your Looks, and easily add which products you used.`}
            />
          </div>
          <div className="inner-container flex flex-row-ns flex-column mt3-ns">
            <Feature
              icon={<Heart />}
              title="Your holy grails"
              description={`Mark products as favorites for quick access.`}
            />
            <Feature
              icon={<Bell />}
              title="Reminders"
              description={`Set an 'opened' date and Vanity will remind you when it's time to toss out that product.`}
              isComingSoon={true}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="main-container pb0 pa4-m pb0-m">
      <MainSection />
    </div>
    <div className="pb0 pa4-m pb0-m">
      <FeaturesSection />
    </div>
  </Layout>
)

export default IndexPage
